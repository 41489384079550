import { phpApi, pyApi } from '@/api/request'
import * as model from '../models'

// 分类列表
export function getNavigationLists(params: object): Promise<Array<model.CateGory>> {
  return phpApi({
    method: 'GET',
    url: `/h5/shop/navigation/lists`,
    params
  })
}

// 分类下内容列表
export function getCategoryContentLists(params: object): Promise<model.ApiCateGoryContent> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/type`,
    params
  })
}

// 查询某个内容分类id结构
export function getCategoryStruct(id: string): Promise<model.CateGory> {
  return phpApi({
    method: 'GET',
    url: `/h5/shop/navigation/type/${id}/struct`
  })
}
