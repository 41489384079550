























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import * as api from '../api'
import * as model from '../models'
import { ListSell } from '@/modules/common/models'
import { time_utils } from '@/utils/time_utils'
import listSell from '@/modules/common/components/list-sell.vue'
import dsPagination from '@/modules/common/components/ds-pagination.vue'
import contentBox from '@/modules/common/components/content-box.vue'
import { utils } from '../../../utils/utils'
import sweepCodeDialog from '@/modules/common/components/sweep-code-dialog.vue';

@Component({
  components: {
    listSell,
    dsPagination,
    contentBox,
    sweepCodeDialog
  }
})
export default class CategoryContentList extends Vue {
@Watch('$route')
  onRouteChange(to: any, from: any) {
    this.categoryId = this.$route.params.category_id
    this.getCategoryStruct()
    this.renderCategoryContentList()
  }

  isLoading: boolean = true
  categoryId: string = ''
  title: string = '分类'
  total: number = 0
  page: number = 1
  count: number = 10
  contentList: Array<model.CateGoryContent> = []
  dialogOption: object = {
    showDialog: false,
    type: '',
    contentId: '',
    title: '更多精彩内容请访问移动端店铺'
  }

  mounted() {
    window.scrollTo(0, 0)
    this.categoryId = this.$route.params.category_id
    this.getCategoryStruct()
  }

  get isHaveData() {
    return this.contentList.length
  }

  get getMoreTips() {
    return `${this.title} (${this.total})`
  }

  getCategoryStruct() {
    api.getCategoryStruct(this.categoryId).then(data => {
      if(data.nodes && data.nodes.length == 0) {
        // 没有下级分类，跳转到内容列表页
        this.renderCategoryContentList()
      } else {
        this.isLoading = false

        // 有下级分类，跳转到分类列表页
        this.dialogOption = {
          showDialog: true,
          type: 'content_category',
          contentId: this.categoryId,
          title: '更多精彩内容请访问移动端店铺'
        }
      }
    }).catch((res: Error) => {
      console.error('getCategoryStruct = ', res)
    })
  }

  renderCategoryContentList() {
    this.page = Number(this.$route.query.page) || 1
    this.count = Number(this.$route.query.count) || 10
    this.getContentList()
  }

  getContentList() {
    this.isLoading = true
    api.getCategoryContentLists({
      page: this.page,
      count: this.count,
      type_id: this.categoryId
    }).then(data => {
      this.contentList = data.data
      this.title = data.belong_type.title || '分类'
      this.total = data.page.total
      this.isLoading = false
    }).catch(res => {
      this.contentList = []
      this.total = 0
      this.isLoading = false
      console.error('getContentList = ', res)
    })
  }

  getListSellItem(item: model.CateGoryContent) {
    let listSellItem: ListSell = {
      content_id: item.content_id || item.id || '',
      type: item.type,
      title: item.title || item.name || '',
      brief: item.brief,
      indexpic: item.indexpic || item.cover_image || '',
      update_time: item.update_time || time_utils.formatDate(time_utils.getTimeStamp(item.latest_lesson_update_time), 'yyyy-MM-dd')  || '',
      highest_price: Number(item.price) || (item.highest_price && (item.highest_price / 100)) || 0,
      lowest_price: Number(item.price) || (item.lowest_price && (item.lowest_price / 100)) || 0,
      comment_count: item.comment_count,
      subscribe_count: item.subscribe || item.total_sales_quantity,
      class_count: item.hour_count,
      start_time: item.start_time,
      end_time: item.end_time,
      original_price: item.original_price,
      view_count: item.view_count
    }
    return listSellItem
  }

  onPageChange(val: number) {
    this.$router.push({
      name: 'categoryContentList',
      query: {
        page: `${val}`,
        count: `${this.count}`
      }
    })
  }
}
